import pic1 from "../../img/paint.png";
import pic2 from "../../img/fork.png";
import pic3 from "../../img/popcorn.png";
import pic4 from "../../img/detective.png";
import pic5 from "../../img/elf.png";
import pic6 from "../../img/orange-leaf.png";
import pic7 from "../../img/moon.png";


const data2 =  {
    en : [{
        "img": pic1,
        "text": "black, wine, dark purple",
        "id": 0
    },

    {
        "img": pic2,
        "text": "pasta, sushi, chicken wings",
        "id": 1
    },
    {
        "img": pic3,
        "text": "cinema, tv series, anime",
        "id": 2
    },
    {
        "img": pic4,
        "text": "crime",
        "id": 3
    },
    {
        "img": pic5,
        "text": "fantasy",
        "id": 4
    },
    {
        "img": pic6,
        "text": "autumn",
        "id": 5
    },
    {
        "img": pic7,
        "text": "the night",
        "id": 6
    }
    ],
    it : [
        {
            "img": pic1,
            "text": "nero, bordeaux, viola scuro",
            "id": 0
        },

        {
            "img": pic2,
            "text": "pasta, sushi, alette di pollo",
            "id": 1
        },
        {
            "img": pic3,
            "text": "cinema, serie tv, anime",
            "id": 2
        },
        {
            "img": pic4,
            "text": "gialli",
            "id": 3
        },
        {
            "img": pic5,
            "text": "fantasy",
            "id": 4
        },
        {
            "img": pic6,
            "text": "l'autunno",
            "id": 5
        },
        {
            "img": pic7,
            "text": "la notte",
            "id": 6
        }

    ]
}

export default data2


