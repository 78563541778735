export function Projects() {
    return(
        <section className="projects">
            <h2 className="txt-c section-title">Progetti</h2>
        <div>
            <h3 className="project-title">
                Lista della spesa
            </h3>
            <p className="project-description">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam, error!
            </p>
            <a href="https://listadellaspesa.altervista.org/" target="_blank">link</a>
        </div>
    </section>
    )
}