import pic from "../../img/cockroach.png";

const data3 = {
    en: 
        {
            "key": "weakness: ",
            "value": " cockroaches",
            "img" : pic,
            "btn":"find out more ",
            "likes": "Likes",
          }

    ,

    it: 
        
        { 
        "key": "punti deboli: ",
        "value": " blatte",
        "img" : pic,
        "btn":"scopri di più ",
        "likes" : "Mi piacciono"
    }
    
}



export default data3


