const data = [
    {
        "btn": "Develhope",
        "title": "Corso Full Stack",
        "date": "02/2022 – 09/2022 ",
        "agency": "Develhope",
        "link": "https://www.develhope.co/",
        "description": "",
        "id": 0
    },
    {
        "btn": "Bc Soft",
        "title": "Academy Java EE",
        "date": " 20/03/2023 – 28/04/2023",
        "agency": "BC Soft",
        "link": "https://www.bcsoft.net/",
        "description": "",
        "id": 1
    },
    {
        "btn": "Openjobmetis",
        "title": "Corso E-Commerce Management con WordPress e WooCommerce",
        "date": "07/06/2023 – 20/07/2023",
        "agency": "Openjobmetis spa",
        "link": "https://www.openjobmetis.it/",
        "description": "",
        "id": 2
    },

]

export default data