import html from "../img/html.png";
import css from "../img/css.png";
import sass from "../img/sass.png";
import bootstrap from "../img/bootstrap.png";
import js from"../img/js.png";
import ts from"../img/ts.png";
import react from "../img/react.png";
import node from "../img/node.png";
import java from "../img/java.png";
import mysql from"../img/mysql.png";
import postgresql from"../img/postgresql.png";

const icon = [
    {
        "html": html,
        "text": "HTML"
    },

    {
        "css": css,
        "sass":sass,
        "bootstrap":  bootstrap,
        "text": "Css, Sass, Bootstrap,\n Ant Design, responsive web design"
    },

    {
        "js": js,
        "ts": ts,
        "react": react,
        "text": "Javascript, Typescript, React"
    },

    {
        "node": node,
        "text": "Node.js"
    },

    {
        "java": java,
        "text": "Java"
    },

    {
        "mysql": mysql,
        "postgresql": postgresql,
        "text": "Database relazionali (MySql, PostgreSQL)"
    },



]

export default icon